import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import React from 'react';
import { useContentfulImages } from '../../hooks/useContentfulImages';
import * as Styled from './slpPropaneImageStyle';

const SlpPropaneImage = ({ sectionData }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.HR]: () => <Styled.HorizontalBreak />,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulImages(node.data.target.sys.id);
        return asset.node.file.url.includes('image') ? (
          <Styled.ImageStyle src={asset.node.file.url} alt={asset.node.title} />
        ) : (
          <></>
        );
      },
    },
  };
  return (
    <Styled.Body>
      <Styled.InnerBody>
        <Styled.BodyContent>
          {sectionData?.contentDetails &&
            documentToReactComponents(
              JSON.parse(sectionData?.contentDetails?.raw),
              optionsMainStyle
            )}
        </Styled.BodyContent>
      </Styled.InnerBody>
    </Styled.Body>
  );
};

export default SlpPropaneImage;
